import axios from 'axios'
import moment from 'moment'
// production
// export const MainUrl = 'https://vriddhiapi.logicloom.in'
//export const MainUrl = 'https://vriddhiapi.deveninfotech.com'

// vridhi staging
// export const MainUrl = 'https://vriddhistageapi.disctesting.in'

//Testing
 export const MainUrl = 'https://bodhavajiapi.disctesting.in'
//local
// export const MainUrl = 'http://localhost:9000'

let BASE_URL = MainUrl + '/api'
export const BASE_URL_NEW = BASE_URL
export const getDataFromApi = (getUrl, model = '') => {
    getUrl =
        getUrl.indexOf('mocky') !== -1
            ? getUrl
            : getUrl.startsWith('/')
                ? `${BASE_URL}${getUrl}`
                : `${BASE_URL}/${getUrl}`
    const resp = axios
        .get(getUrl)
        .then((res) => {
            return res
        })
        .catch((err) => {
            //  console.log('Error', err)
        })
    return resp
}

// call the api (PUT) endpoint internal system
export const putDataFromApi = (putUrl, model) => {
    putUrl = putUrl.startsWith('/')
        ? `${BASE_URL}${putUrl}`
        : `${BASE_URL}/${putUrl}`
    const resp = axios
        .put(putUrl, model)
        .then((res) => {
            return res
        })
        .catch((err) => {
            //  console.log('Error', err)
        })
    return resp
}

// call the api (POST) endpoint internal system
export const postDataFromApi = async (postUrl, model) => {
    postUrl = postUrl.startsWith('/')
        ? `${BASE_URL}${postUrl}`
        : `${BASE_URL}/${postUrl}`
    const resp = await axios
        .post(postUrl, model)
        .then((res) => {
            return res
        })
        .catch((err) => {
            // (resp.data.message)
            //  console.log(err)
        })
    return resp
}

// call the api (DELETE) endpoint internal system
export const deleteDataFromApi = (deleteUrl, model) => {
    deleteUrl = deleteUrl.startsWith('/')
        ? `${BASE_URL}${deleteUrl}`
        : `${BASE_URL}/${deleteUrl}`
    const resp = axios
        .delete(deleteUrl, { data: model })
        .then((res) => {
            return res
        })
        .catch((err) => {
            //  console.log('Error', err)
        })
    return resp
}

export const getDateFormat = (date = '') => {
    var formateddate = date ? moment(date).format('DD-MM-Y hh:mm A') : ''
    return formateddate
}
// export const orderStatuses=['pending from bm','bm approved','reject BM','ready for loading','raise concern from yard','Hold order from BM','rejected from credit team','order loaded','order delivered'];
export const orderStatuses = [
    'BM Approval Pending',
    'Credit Approval Pending',
    'Rejected By BM',
    'Waiting for Loading',
    'Raise Concern From Yard',
    'Hold order from BM',
    'Rejected From Credit Team',
    'Order Loaded',
    'Order Completed',
    'Draft',
    'Raise Concern By Account',
    'Preliminary Credit Team',
]
export const intergodownTransferStatuses = [
    'BM created',
    'Ready to loading',
    'intrasite',
    ' Yard Inward',
]
export const getOnlyDateFormat = (date = '') => {
    var formateddate = date ? moment(date).format('DD-MM-Y') : ''
    return formateddate
}
